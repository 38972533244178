Vue.component('vue-search-global', {
    delimiters: ['${', '}'],

    template: '#vue-search-global-template',

    props: ['query_search'],

    data() {
        return {
            search_term: this.query_search,
            show: false,
        }
    },

    computed: {
        search_query() { return this.search_term ? 'q=' + this.search_term : '' },
        url() { return location.protocol + '//' + location.host + '/search' + '?' + this.search_query },
        is_results_page() { return window.location.pathname == '/search' },
    },

    methods: {
        search() {
            if (this.search_term) {
                this.reload();
            }
        },
        toggleSearch() {
            this.show = ! this.show;

            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.search_box.focus();
                })
            }
        },
        clearSearch() {
            this.search_term = '';
        },
        reload() {
            window.location = this.url;
        },
    },

    created() {
        if (this.is_results_page) {
            this.show = true;
        }
    },
});
